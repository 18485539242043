import safeJSON from 'src/utils/safeJSON'
import createMessageHandler from 'src/utils/createMessageHandler'

const searchToDonate = ({ dataset, element, iframe, origin }) => {
  if (!dataset.searchToDonate) return

  const handleSearchToDonate = data => {
    const donateElement = document.createElement('div')
    donateElement.className = 'plg-donate'
    donateElement.setAttribute('data-partner-key', dataset.partnerKey)
    donateElement.setAttribute('data-ein', data.ein)
    donateElement.setAttribute('data-x-search-to-donate', 1)
    if (dataset.itemId)
      donateElement.setAttribute('data-item-id', dataset.itemId)

    element.style.display = 'none'
    element.parentNode.insertBefore(donateElement, element)
    element.donateElement = donateElement

    iframe.contentWindow.postMessage(JSON.stringify({
      action: 'searchToDonateMessageReceived',
    }), origin)
  }

  const handleReturnToSearchWidget = () => {
    element.parentNode.removeChild(element.donateElement)
    element.style.display = ''
  }

  const handleMessageFromSearch = e => {
    if (e.source != iframe.contentWindow) return

    const data = safeJSON(e.data)

    switch (data.action) {
      case 'searchToDonateOrganizationSet':
        return handleSearchToDonate(data.data)
    }
  }

  const handleMessageFromDonate = e => {
    const donateIframe = element.donateElement?.querySelector('iframe')
    if (!donateIframe || e.source != donateIframe.contentWindow) return

    const data = safeJSON(e.data)

    switch (data.action) {
      case 'searchToDonateOrganizationCleared':
        return handleReturnToSearchWidget()
    }
  }

  addEventListener('message', createMessageHandler(origin, handleMessageFromSearch))
  addEventListener('message', createMessageHandler(origin, handleMessageFromDonate))
}

export default searchToDonate
