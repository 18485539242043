import Donate from './Donate'
import Impact from './Impact'
import Search from './Search'
import Crowd  from './Crowd'
import Host   from './Host'
import Goal   from './Goal'

const Config = {
  LOAD_ATTR: 'data-stay-back-this-widget-is-loaded',
  Widgets: {
    [Donate.className]: Donate,
    [Impact.className]: Impact,
    [Search.className]: Search,
    [Crowd.className]:  Crowd,
    [Host.className]:   Host,
    [Goal.className]:   Goal,
  },
}

export default Config
